import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { defaultProps } from "../types"
import tower from "../assets/tower.jpg"
import rome from "../assets/rome.jpg"
import svarthaugen from "../assets/svarthaugen.jpg"
import bybrua from "../assets/bybroa.jpg"
import lom from "../assets/lom.jpg"
import ImageCard from "../components/ImageCard"

const dummyText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius, sem hendrerit suscipit pretium, neque ante condimentum urna, eu pretium nunc mi nec mi. Sed accumsan, velit quis mattis condimentum, libero lectus ornare urna, sit amet dictum felis sapien sed dui. Aliquam mattis rhoncus nunc, ut dictum lacus tempus et. Ut eleifend urna sed varius commodo. Cras feugiat purus eget odio auctor volutpat. Maecenas nec sodales felis. Mauris consectetur, metus et ultrices egestas, nisi erat interdum urna, in porttitor odio metus a nulla. Phasellus eu quam laoreet, dictum neque feugiat, tempus erat."

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
      },
      color: "white",
    },
    card: {
      maxWidth: 345,
      marginBottom: 50
    },
    mobile_portrait: {
      maxWidth: "50%",
      height: "auto"
    },
    splash: {
      maxWidth: "100%",
      height: "auto",
      borderRadius: "50%",
      padding: theme.spacing(3)
    },
    portrait: {
      width: "100%",
    },
    text: {
      display: "flex",
      width: "100%",
      height: "80vh",
      [theme.breakpoints.up('sm')]: {
        height: "20vh",
      },
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    }
  })
);

function Personal(props: defaultProps) {
  const classes = useStyles();
  const { isMobile } = props
  return (
    <div className={`${classes.root}`}>
      <ImageCard
        title={"Roma"}
        media={rome}
        text={dummyText}
        isMobile={isMobile}
        left={true}
      />
      <ImageCard
        title={"Tower"}
        media={tower}
        text={dummyText}
        isMobile={isMobile}
        left={false}
      />
      <ImageCard
        title={"bybrua"}
        media={bybrua}
        text={dummyText}
        isMobile={isMobile}
        left={true}
      />
      <ImageCard
        title={"Svarthaugen"}
        media={svarthaugen}
        text={dummyText}
        isMobile={isMobile}
        left={false}
      />
      <ImageCard
        title={"Stavkirke"}
        media={lom}
        text={dummyText}
        isMobile={isMobile}
        left={true}
      />
    </div>
  );
}

Personal.displayName = 'Personal';

export default Personal;