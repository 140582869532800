import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import background from "../assets/pixel_bg_min.png"
import self_portrait from "../assets/self_portrait.png"


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",
            left: 0,
            top: 0,
            overflow: "hidden",
            position: "fixed",
            background: `url(${background}) no-repeat center center fixed`,
            backgroundSize: "cover",
            zIndex: -1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        selfPortrait: {
            paddingBottom: theme.spacing(10)
        },
        selfPortraitImg: {
            [theme.breakpoints.down('sm')]: {
                width: "50%",
                height: "auto"
            },
        }
    })
);

function Pixel() {
    const CANVAS_WIDTH = window.innerWidth;
    console.log(CANVAS_WIDTH)


    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.selfPortrait}>
                <img className={classes.selfPortraitImg} src={self_portrait} alt="Pixel self portrait of Odd Gunnar Aspaas" />
            </div>
        </div>
    );
}

Pixel.displayName = 'Pixel';

export default Pixel;