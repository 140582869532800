import React from "react";
import { defaultProps } from "../types"
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "50vh",
    }
  })
);


function CV(props: defaultProps) {
  const classes = useStyles();
  const { isMobile } = props

  return (
    <div className={classes.root}>
      {isMobile && (
        <Typography variant="h4">Coming soon.</Typography>
      )}
      {!isMobile && (
        <Typography variant="h6">Coming soon.</Typography>
      )}
    </div>
  );
}

CV.displayName = 'CV';

export default CV;