import React from "react";
import background from "../assets/background-min-2.jpg"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { defaultProps } from "../types"
import { Typography } from "@material-ui/core";
import Socials from "./Socials/Socials";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      left: 0,
      top: 0,
      overflow: "hidden",
      position: "fixed",
      background: `url(${background}) no-repeat center center fixed`,
      backgroundSize: "cover",
      zIndex: -1,
      display: 'flex',
      flexDirection: "column",
      justifyContent: "space-between",
      paddingLeft: 0,
    },
    logo: {
      display: "flex",
      justifySelf: "flex-start",
      color: "#603D3B",
      paddingLeft: theme.spacing(10)
    },
    socials: {
      paddingBottom: "2%",
      [theme.breakpoints.up('lg')]: {
        paddingLeft: "60%",
      },
      [theme.breakpoints.down('lg')]: {
        paddingLeft: "50%",
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: "40%",
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "0%",
      },
    }
  })
);

function Home(props: defaultProps) {
  const TIMEOUT = 1000
  const isMobile = props.isMobile
  const styleProps = {
    isMobile: isMobile,
    drawerWidth: isMobile ? 180 : 240
  }
  const classes = useStyles(styleProps);

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.logo}>oga.</Typography>
      <div className={classes.socials}>
        <Socials delay={TIMEOUT} />
      </div>
    </div>
  );
}

Home.displayName = 'Home';

export default Home;
