import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "50vh",
        }
    })
);


function Projects() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h6">Coming soon.</Typography>
        </div>
    );
}

Projects.displayName = 'Projects';

export default Projects;