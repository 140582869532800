import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { cardProps } from "../types"
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import clsx from 'clsx';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginBottom: theme.spacing(2),
      width: "100%",
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          backgroundImage: (props: { drawerWidth: number, isMobile: boolean, media: string }) => `url(${props.media})`,
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
      },
    },
    card: {
      backgroundColor: "inherit",
      display: "flex",
      flexDirection: "row",
      boxShadow: "none",
      width: "100%",
      marginBottom: theme.spacing(2),
      color: "white",
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
      },
    },
    media: {
      display: "flex",
      flexDirection: "row",
      height: 0,
      padding: "23%",
      [theme.breakpoints.down('sm')]: {
        padding: "50%",
      },
      borderRadius: "1%"
    },
    reverse: {
      display: "flex",
      flexDirection: "row-reverse"
    },
    cardContent: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down('sm')]: {
        fontSize: 40,
      },
    },
    content: {
      textAlign: "start",
      '&:hover': {
        backgroundColor: "#00000033"
      }
    }
  })
);

function ImageCard(props: cardProps) {
  const TIMEOUT = 2000
  const { title, media, text, isMobile, left } = props
  const styleProps = {
    isMobile: isMobile,
    drawerWidth: isMobile ? 180 : 240,
    media: media
  }
  const classes = useStyles(styleProps);

  const [show, setShow] = React.useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, TIMEOUT * 1.5)
  }, [show])


  return (
    <div className={classes.root}>
      <Card className={clsx(classes.card, (!isMobile && left) && classes.reverse)}>
        <CardMedia
          className={classes.media}
          image={media}
          title="Self portait"
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="h2" className={classes.title}>
            {[...title.toUpperCase()].map((character, index) => <span>{character}</span>)}
          </Typography>
          <Typography variant="body1" component="p" className={classes.content}>
            {text}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

ImageCard.displayName = 'ImageCard';

export default ImageCard;

