import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Fade, Link, SvgIcon } from "@material-ui/core";
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as GitHubIcon } from '../../assets/icons/github.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "space-around",
            padding: 0,
            paddingLeft: 0,
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(2),
                paddingLeft: theme.spacing(10),
            },
            color: "white",
        },
        icon_wrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "64px",
            [theme.breakpoints.up('sm')]: {
                width: "64px",
            },
            height: "64px",
            [theme.breakpoints.up('sm')]: {
                height: "64px",
            },
        },
        icon: {
            color: "#FFFFFF66",
            width: "95%",
            height: "95%",
            '&:hover': {
                animation: `$highlight 250ms ${theme.transitions.easing.easeInOut}`,
                color: "#FFFFFF99",
                height: "100%",
                width: "100%"
            },
        },
        "@keyframes highlight": {
            "0%": {
                color: "#FFFFFF66",
                height: "95%",
                width: "95%"
            },
            "100%": {
                color: "#FFFFFF99",
                height: "100%",
                width: "100%"
            }
        },
    })
);

function Socials(props: { delay: number }) {
    const TIMEOUT = 3000
    const classes = useStyles();
    const [instagram, setInstagram] = useState(false)
    const [facebook, setFacebook] = useState(false)
    const [linkedIn, setLinkedIn] = useState(false)
    const [github, setGithub] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setTimeout(() => {
                setInstagram(true)
            }, 0)
            setTimeout(() => {
                setFacebook(true)
            }, 500)
            setTimeout(() => {
                setLinkedIn(true)
            }, 1000)
            setTimeout(() => {
                setGithub(true)
            }, 1500)
        }, props.delay)

    }, [instagram, facebook, linkedIn, github, props.delay])


    return (
        <div className={classes.root}>
            <Fade in={instagram} timeout={TIMEOUT} disableStrictModeCompat={true}>
                <div className={classes.icon_wrapper}>
                    <Link href="https://www.instagram.com/ogaspaas/" target="_blank" rel="noopener">
                        <SvgIcon className={classes.icon} component={InstagramIcon} viewBox="0 0 96 96" />
                    </Link>
                </div>
            </Fade>
            <Fade in={facebook} timeout={TIMEOUT} disableStrictModeCompat={true}>
                <div className={classes.icon_wrapper}>
                    <Link href="https://www.facebook.com/oddaspa/" target="_blank" rel="noopener">
                        <SvgIcon className={classes.icon} component={FacebookIcon} viewBox="0 0 96 96" />
                    </Link>
                </div>
            </Fade>
            <Fade in={linkedIn} timeout={TIMEOUT} disableStrictModeCompat={true}>
                <div className={classes.icon_wrapper}>
                    <Link href="https://www.linkedin.com/in/ogaspaas/" target="_blank" rel="noopener">
                        <SvgIcon className={classes.icon} component={LinkedInIcon} viewBox="0 0 96 96" />
                    </Link>
                </div>
            </Fade>
            <Fade in={github} timeout={TIMEOUT} disableStrictModeCompat={true}>
                <div className={classes.icon_wrapper}>
                    <Link href="https://github.com/oddaspa/" target="_blank" rel="noopener">
                        <SvgIcon className={classes.icon} component={GitHubIcon} viewBox="0 0 96 96" />
                    </Link>
                </div>
            </Fade>
        </div>
    );
}

Socials.displayName = 'Socials';

export default Socials;
