import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
//import Navbar from './NavBar/Navbar';
import { CssBaseline, Theme, ThemeProvider, useMediaQuery } from '@material-ui/core';
import Home from './Home/Home';
import Personal from './Personal/Personal';
import CV from './CV/CV';
import Pixel from './Pixel/Pixel'
import { createMuiTheme } from '@material-ui/core/styles';
import Projects from './Projects/Projects';

let theme: Theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Josefin Sans',
      'sans-serif',
    ].join(','),
    fontWeightMedium: 100,
    h1: {
      fontWeight: 100,
    },
    h2: {
      fontWeight: 200,
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF"
    },
    background: {
      default: "#282c34"
    }
  }
});



function App() {
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          {/*<Navbar isMobile={isMobile} />*/}
          <Switch>
            <Route exact path="/">
              <Home isMobile={isMobile} />
            </Route>
            <Route path="/explore">
              <Personal isMobile={isMobile} />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/contact">
              <CV isMobile={isMobile} />
            </Route>
            <Route path="/pixel">
              <Pixel />
            </Route>
          </Switch>
        </div>
      </ThemeProvider>
    </Router>

  );
}

export default App;
